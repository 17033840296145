
$animation-delay: 300ms;

@media(min-width: $break-lg) {
  [data-ani] {
    opacity: 0;
    animation-duration: 500ms;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-delay: $animation-delay;
  }
  [data-ani="up"][data-ani-run] {
    animation-name: fadeUp;
  }
  [data-ani="left"][data-ani-run] {
    animation-name: fadeLeft;
  }
  [data-ani="right"][data-ani-run] {
    animation-name: fadeRight;
  }
  @for $i from 1 through 24 {
    [data-ani-delay="#{$i * 25}"] {
      animation-delay: $i * 25ms + $animation-delay;
    }
  }
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeLeft {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeRight {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
