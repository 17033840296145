

.nav {
  $logo-height: 3em;
  $logo-padding: 1em;
  $border: 5px;
  z-index: 1000;
  background: white;
  border-bottom: $border solid $color-grey5;
  user-select: none;
  // sticky positioning
  @media (max-width: $break-lg - 0.02px) and (min-height: 500px), (min-width: $break-lg) and (min-height: 1000px) {
    position: sticky;
    top: -($logo-height + $logo-padding + .05em);
  }
  @media (min-width: $break-lg) {
    // position sticky flickers on edge
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    & + main {
      padding-top: calc(#{$logo-height} + #{2 * $gutter + $border});
    }
  }
  // font size
  @include fontViewportScale(320px, $break-lg, 12px, 18px);
  @media (min-width: $break-lg) {
    font-size: 18px;
  }
  &_container {
    margin: 0 auto;
    padding: 0 $gutter / 2;
    box-sizing: border-box;
    max-width: 600px;
    @media (min-width: $break-lg) {
      max-width: $max-width;
      display: flex;
      justify-content: space-between;
    }
  }
  &_logo {
    padding-top: $logo-padding;
    @media (min-width: $break-lg) {
      padding: $gutter $gutter / 2;
    }
    &-link {
      display: block;
    }
    &-img {
      height: $logo-height;
      width: 6862 / 2004 * $logo-height;
      display: block;
      margin: 0 auto;
    }
  }
  &_main {
    display: flex;
    list-style: none;
    margin: 0;
    flex-grow: 1;
    width: 100%; // ie fix
    padding: 0;
    border-top: $border solid transparent;
    @media (min-width: $break-lg) {
      max-width: 39em;
      padding: 0 $gutter / 2;
    }
    &-item {
      display: flex;
      position: relative;
      flex-grow: 1;
      &:hover:before,
      &:focus:before {
        content: '';
        @include absolute(0, 0, -$border, 0);
        border-bottom: $border solid $color-red;
        @media (min-width: $break-lg) {
          left: $padding / 2;
          right: $padding / 2;
        }
      }
      // ie11 doesn't like focus-within merged
      &:focus-within:before {
        content: '';
        @include absolute(0, 0, -$border, 0);
        border-bottom: $border solid $color-red;
        @media (min-width: $break-lg) {
          left: $padding / 2;
          right: $padding / 2;
        }
      }
    }
    &-link {
      color: $color-text-black;
      text-decoration: none;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      width: 100%;
      text-transform: uppercase;
      padding: 1em 0;
      position: relative;
      font-family: $font-display;
      outline: none;
      &:hover,
      &:focus {
        color: $color-red;
      }
    }
  }
  &_sub {
    display: none;
    position: absolute;
    top: 100%;
    margin: $border 0 0 0;
    white-space: nowrap;
    list-style: none;
    background: $color-grey3;
    min-width: calc(100% - #{$padding});
    z-index: 100;
    left: 0;
    padding: 0.5em 0;
    @media (min-width: $break-lg)  {
      padding: 7px 0;
      left: $padding / 2;
      font-size: $font-size;
    }
    &-link {
      padding: 0.5em 1em;
      color: $color-text-white;
      text-decoration: none;
      display: flex;
      align-items: center;
      outline: none;
      &:hover,
      &:focus {
        background: white;
        color: $color-red;
      }
      @media (min-width: $break-lg) {
        padding: 7px $gutter;
      }
    }
    &-kicker {
      color: $color-red;
      text-transform: uppercase;
      padding-right: 8px;
    }
  }
  &_main-item:hover &_sub,
  &_main-link:focus + &_sub {
    display: block;
  }
  // ie11 doesn't like focus-within merged
  &_main-item:focus-within &_sub {
    display: block;
  }
  &_main-item:hover &_sub {
    z-index: 101;
  }
}



