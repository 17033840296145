

.footer {
  $line-padding: 5px;
  background: $color-grey3;
  &_center {
    margin: 0 auto;
    box-sizing: border-box;
    padding: $padding - $line-padding $gutter;
    max-width: $max-width;
    text-align: center;
    color: $color-text-white;
  }
  &_item {
    padding: $line-padding 0 $line-padding 0;
    &:last-child {
      margin-top: $padding;
    }
  }
  @media (min-width: $break-lg) {
    &_center {
      display: flex;
    }
    &_item {
      padding-right: $padding;
      &:last-child {
        padding-right: 0;
        margin-top: 0;
        margin-left: auto;
      }
    }
  }
}
