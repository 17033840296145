


.highlight {
  @include padding-main;
  position: relative;
  background-color: $color-grey1;
  &--blue {
    background-color: $color-blue;
    color: $color-text-white;
  }
  &--green {
    background-color: $color-green;
    color: $color-text-white;
  }
  &--flip &_grid {
    @media (min-width: $break-lg) {
      flex-direction: row-reverse;
    }
  }
  &_anchor {
    position: absolute;
    top: -50px;
    @media (min-width: $break-sm) {
      top: -75px;
    }
    @media (min-width: $break-lg) {
      top: -100px;
    }
  }
  &_grid {
    @include grid;
    justify-content: space-between;
  }
  &_col-text {
    @include column-base;
    @include column(lg, 11);
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    @media (min-width: $break-lg) {
      padding-bottom: 0;
    }
  }
  &_col-tiles {
    width: 100%;
    display: flex;
    align-items: center;
    @include column(lg, 12);
  }
  &_text {
    width: 100%;
    max-width: 28em;
    @media (min-width: $break-lg) {
      margin: 0 auto;
      max-width: 22em;
      font-size: 110%;
    }
    @media (min-width: $break-xl) {
      font-size: 120%;
    }
  }
  &_kicker {
    @include kicker;
  }
  @media (min-width: $break-lg) {
    &--default + &--default,
    &--blue + &--blue {
      padding-top: 0;
    }
  }
}
