.text {

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  p, ul, ol {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    overflow-wrap: break-word;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: $font-display;
    font-weight: bold;
    line-height: 1.3;
    overflow-wrap: break-word;
  }

  @mixin headingSize($size) {
    font-size: $size * 1em;
    margin: 2.75em / $size 0 1.25em / $size;
  }

  h1 {
    @include headingSize(2.488);
  }

  h2 {
    @include headingSize(2.074);
  }

  h3 {
    @include headingSize(1.728);
  }

  h4 {
    @include headingSize(1.44);
  }

  h5 {
    @include headingSize(1.2);
  }

  h6 {
    @include headingSize(1);
  }

  ol {
    padding: 0;
    counter-reset: list;
  }

  ol li {
    counter-increment: list;

    &:before {
      content: counter(list) '.';
    }
  }

  ul {
    padding: 0;
  }

  ul li {
    list-style: none;
    padding: 0 0 0 1.5em;

    &:before {
      content: '-';
      width: 1.5em;
      text-align: left;
      display: inline-block;
      margin-left: -1.5em;
    }
  }

  hr {
    border: 0;
    border-top: 1px solid black;
    margin: 2.75em 0;
  }

  a {
    color: inherit;
    text-decoration-color: $color-red;

    &:hover {
      color: $color-red;
    }
  }

  [data-email] {
    direction: rtl;
    unicode-bidi: bidi-override;
    text-decoration: underline;
    text-decoration-color: $color-red;
    // ie11 messes up rtl rendering of some custom fonts
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      font-family: 'Calibri', sans-serif;
    }
  }
}

