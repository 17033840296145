
.list {
  @include padding-main;
  background: white;
  position: relative;
  &_anchor {
    position: absolute;
    top: -50px;
    @media (min-width: $break-sm) {
      top: -75px;
    }
    @media (min-width: $break-lg) {
      top: -100px;
    }
  }
  &_grid {
    @include grid;
    margin: -$compact / 2 auto;
    @media (min-width: $break-lg) {
      margin: -$gutter / 2 auto;
    }
  }
  &_grid--center {
    @media (min-width: $break-lg) {
      justify-content: center;
    }
  }
  &_col {
    @include column-base;
    @include column(null, 12);
    @include column(lg, 6);
    padding: $compact / 2;
    @media (min-width: $break-lg) {
      padding: $gutter / 2;
    }
    display: flex;
  }
  &_inner {
    text-decoration: none;
    display: block;
    width: 100%;
    position: relative;
    border-radius: $border-radius;
    overflow: hidden;
    img {
      transition: transform 0.2s ease;
    }
    &:hover img {
      transform: scale(1.05);
    }
    &:hover:after {
      content: '';
      position: absolute;
      z-index: 100;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      background-size: 80%;
      background-repeat: no-repeat;
      background-position: center;
    }
    &[href]:after {
      background-color: $color-grey2;
      background-image: url(/assets/img/icon-arrow-right.svg);
    }
    &[href^="#"]:after {
      background-color: $color-grey2;
      background-image: url(/assets/img/icon-arrow-down.svg);
    }
  }
  &_picture {
    @include picture-ratio;
    padding-top: 100% / 16 * 9;
    overflow: hidden;
  }
  &_label {
    @include absolute;
    padding: 0 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-display;
    word-break: break-word;
    color: $color-text-white;
    text-shadow: $text-shadow;
    line-height: 1.4;
    background: rgba(0, 0, 60, 0.55);
    @include fontViewportScale(320px, $break-sm, 16px, 20px);
    @media (min-width: $break-sm) {
      font-size: 20px;
    }
  }
}

