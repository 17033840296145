

.numbers {
  background-color: white;
  &_grid {
    @include grid;
    padding-top: 50px;
    padding-bottom: 25px;
    @media (min-width: $break-lg) {
      padding-top: 50px;
    }
  }
  &_col {
    @include column-base;
    @include column(null, 12);
    @include column(sm, 6);
    padding-bottom: 25px;
    @media (min-width: $break-lg) {
      padding-bottom: 50px;
    }
  }
  &_number {
    color: $color-red;
    font-size: 2.488em;
    font-family: $font-display;
    font-weight: bold;
    text-align: center;
  }
  &_text {
    width: 6em;
    text-align: center;
    margin: 0 auto;
    text-transform: uppercase;
    @media (min-width: $break-lg) {
      width: 100%;
    }
  }
}


.team {
  background: $color-grey1;
  @include padding-main;
  &_wrap {
    @include grid;
  }
  &_heading {
    box-sizing: border-box;
    margin-bottom: 50px;
    padding: 0 $compact / 2;
    max-width: 28em;
    @media (min-width: $break-lg) {
      margin: 0 auto 100px auto;
      text-align: center;
      max-width: $max-width;
      padding: 0 $gutter / 2;
      font-size: 110%;
    }
    @media (min-width: $break-xl) {
      font-size: 120%;
    }
  }
  &_kicker {
    @include kicker;
  }
  &_grid {
    @include grid;
    margin: -$gutter / 2 auto;
    @media (min-width: $break-lg) {
      margin: -$gutter / 2 auto;
    }
  }
  &_col {
    @include column-base;
    @include column(null, 12);
    @include column(lg, 6);
    display: flex;
    padding: $gutter / 2 $compact / 2;
    @media (min-width: $break-lg) {
      padding: $gutter / 2;
    }
  }
  &_inner {
    font-size: 14px;
    width: 100%;
    text-align: center;
    @media (min-width: $break-sm) {
      font-size: 16px;
    }
  }
  &_picture-wrap {
    max-width: 200px;
    width: 80%;
    margin: 0 auto 1.25em auto;
  }
  &_picture {
    background: #2c2943;
    border-radius: 50%;
    padding-top: 100%;
    overflow: hidden;
    @include picture-ratio;
  }
  &_placeholder {
    background: $color-green;
    border-radius: 50%;
    padding-top: 100%;
  }
}




.history {
  @include padding-main;
  background: white;
  &_wrap {
    @include grid;
  }
  &_heading {
    box-sizing: border-box;
    margin-bottom: 50px;
    padding: 0 $compact / 2;
    max-width: 28em;
    @media (min-width: $break-lg) {
      margin: 0 auto 100px auto;
      text-align: center;
      max-width: $max-width;
      padding: 0 $gutter / 2;
      font-size: 110%;
    }
    @media (min-width: $break-xl) {
      font-size: 120%;
    }
  }
  &_kicker {
    @include kicker;
  }
  &_grid {
    @include grid;
    margin: -$compact / 2 auto;
    @media (min-width: $break-lg) {
      margin: -$gutter / 2 auto;
    }
  }
  &_col {
    @include column-base;
    @include column(null, 12);
    @include column(lg, 8);
    @include column(xl, 6);
    display: flex;
    position: relative;
    padding: $compact / 2;
    @media (min-width: $break-lg) {
      padding: $gutter / 2;
    }
  }
  &_inner {
    background: white;
    width: 100%;
    border-radius: $border-radius;
    overflow: hidden;
    font-size: 13px;
    color: white;
    background: $color-blue;
    @media (min-width: $break-sm) {
      font-size: 14px;
    }
    @media (min-width: $break-lg) {
      font-size: 16px;
    }
  }
  &_link {
    display: block;
    position: relative;
    &:hover:after {
      content: '';
      position: absolute;
      z-index: 100;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      background-size: 80%;
      background-repeat: no-repeat;
      background-position: center;
      background-color: $color-grey2;
      background-image: url(/assets/img/icon-fullscreen.svg);
    }
  }
  &_picture {
    @include picture-ratio;
    padding-top: 100% / 3 * 2;
    overflow: hidden;
    &:after {
      content: '';
      @include absolute;
    }
    img {
      transition: transform 0.2s ease;
    }
    &:hover img {
      transform: scale(1.05);
    }
  }
  &_text {
    pointer-events: none;
    padding: 3em $compact $compact $compact;
    margin-top: -3em;
    background: linear-gradient(transparent, #363636 3em);
    position: relative;
    min-height: 3em * 1.7;
    @media (min-width: $break-lg) {
      padding: 3em $gutter $gutter $gutter;
    }
  }
  &_year {
    position: absolute;
    font-size: 2.074em;
    text-shadow: 1px 1px 3px #000;
    color: white;
    margin: 0;
    pointer-events: none;
    left: $compact;
    bottom: 8px;
    z-index: 1;
    font-family: "Palatino Linotype", Palatino, Palladio, "URW Palladio L", "Book Antiqua", Baskerville, "Bookman Old Style", "Bitstream Charter", "Nimbus Roman No9 L", Garamond, "Apple Garamond", "ITC Garamond Narrow", "New Century Schoolbook", "Century Schoolbook", "Century Schoolbook L", Georgia, serif;
    font-style: italic;
    letter-spacing: 1px;
    @media (min-width: $break-lg) {
      left: $gutter;
    }
  }
}






