
html {
  font-family: $font-text;
  font-size: $font-size;
  line-height: $line-height;
  color: $color-text-black;
  background-color: #e9e9e9;
}

.hidden {
  display: none;
}

