.simple-text {
  @include padding-main;
  background-color: $color-grey1;
  &_text {
    max-width: 600px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 $compact 50px $compact;
    @media (min-width: $break-lg) {
      font-size: 110%;
      padding: 0 $gutter 100px $gutter;
    }
    @media (min-width: $break-xl) {
      font-size: 120%;
    }
  }
}
