.hero {
  position: relative;
  background: black;
  @media (min-width: $break-sm) {
    @include fontViewportScale($break-sm, $break-xl, 16px, 24px);
  }
  @media (min-width: $break-xl) {
    font-size: 24px;
  }
  &_picture {
    @include absolute;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      // edge can't unset object-fit
      @media(max-width: $break-lg - 0.02px) {
        object-fit: cover;
        object-position: center;
      }
      @media(min-width: $break-lg) {
        position: absolute;
        top: 50%;
        left: 0;
        height: auto;
        transform: translateY(-50%);
      }
    }
    &:after {
      content: '';
      @include absolute;
      background: rgba(0, 0, 0, 0.5);
      @media(min-width: $break-lg) {
        background: rgba(0, 0, 0, 0.3);
      }
    }
  }
  &_grid {
    @include grid;
    padding-top: 75px;
    padding-bottom: 75px;
    align-items: center;
    display: flex;
    position: relative;
    min-height: 50vw;
    @media (min-width: $break-lg) {
      height: 600px; // ie fix
      min-height: 600px;
    }
    @media (min-width: $break-xl) {
      height: 700px; // ie fix
      min-height: 700px;
    }
  }
  &_col {
    @include column-base;
  }
  &_text {
    color: $color-text-white;
    text-shadow: $text-shadow;
    max-width: 28em;
    // recreate padding from other elements
    @media (min-width: $break-lg) {
      max-width: 22em;
      padding-left: calc(#{100% / 24 * 11 / 2} - #{1.1 * 22 / 2 * 16px});
    }
    @media (min-width: $break-xl) {
      padding-left: calc(#{100% / 24 * 11 / 2} - #{1.2 * 22 / 2 * 16px});
    }
  }
  &_logo {
    margin: 0 auto;
    display: block;
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, .5));
    width: 700px;
    max-width: 80%;
    h1 {
      padding-top: 2048 / 6906 * 100%;
      position: relative;
    }
    img {
      @include absolute;
      width: 100%;
    }
  }
  &_scroll {
    $nav-height: 107px;
    &-arrow {
      display: none;
      $size: 75px;
      overflow: hidden;
      color: transparent;
      position: absolute;
      top: calc(100vh - #{$nav-height});
      left: calc(50% - #{$size / 2});
      width: $size;
      height: $size;
      background: white;
      cursor: pointer;
      margin-top: -$size * 1.1;
      background: url(/assets/img/icon-arrow-down-white.svg) center no-repeat;
      background-size: 100%;
      opacity: 0;
      animation: 500ms ease-out forwards 1s heroScroll1, 3s ease-out 2s infinite heroScroll2;
      @media (min-width: $break-lg) and (min-height: 640px) and (max-height: 720px) {
        display: block;
      }
      @media (min-width: $break-xl) and (min-height: 700px) and (max-height: 820px) {
        display: block;
      }
    }
    &-anchor {
      position: absolute;
      bottom: $nav-height;
    }
  }
}



@keyframes heroScroll1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes heroScroll2 {
  0% {
    transform: translateY(0);
  }
  4% {
    transform: translateY(4px);
  }
  8% {
    transform: translateY(0);
  }
  12% {
    transform: translateY(4px);
  }
  16% {
    transform: translateY(0);
  }
  18% {
    transform: translateY(0);
  }
}
