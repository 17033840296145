.news {
  @include padding-main;
  background-color: $color-grey1;
  &_kicker {
    @include kicker;
  }
  &_kicker-tile {
    text-transform: uppercase;
    font-size: 80%;
    color: $color-red;
  }
  &_video {
    position: relative;
    margin-bottom: 20px;
    border-radius: $border-radius;
    overflow: hidden;
    &-ratio {
      padding-top: 56.25%
    }
    &-iframe {
      position: absolute;
      inset: 0;
      border: 0;
    }
    &-preview {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-privacy {
      display: block;
      position: absolute;
      inset: 0;
      &:hover &-button {
        background-color: rgba(0,0,0,0.2);
      }
      &-text {
        position: absolute;
        top: calc(50% + 70px);
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        max-width: 90%;
        line-height: 1.5;
        color: white;
        padding: 5px;
        border-radius: 5px;
        text-align: center;
        background-color: rgba(0,0,0,0.6);
        font-size: 12px;
        @media (min-width: $break-lg) {
          font-size: 16px;
        }
      }
      &-button {
        position: absolute;
        border: 0;
        inset: 0;
        background-color: rgba(0,0,0,0.3);
        display: block;
        cursor: pointer;
        transition: background-color 200ms ease-in-out;
        & a {
          text-underline-color: white;
        }
      }
      &-logo {
        position: absolute;
        bottom: 15px;
        right: 15px;
        opacity: 90;
        width: 110px;
        height: auto;
      }
      &-play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 68px;
        height: auto;
      }
    }
  }
  &_pictures {
    margin: 0 -$compact / 2;
    @media (min-width: $break-lg) {
      margin: 0 -$gutter / 2;
    }
  }
  &_text {
    max-width: 600px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 $compact;
    @media (min-width: $break-lg) {
      font-size: 110%;
      padding: 0 $gutter;
    }
    @media (min-width: $break-xl) {
      font-size: 120%;
    }
  }
}
