
$font-size: 16px;
$line-height: 1.7;

$font-text: 'Source Sans Pro', 'Calibri', 'Helvetica', 'Arial', sans-serif;
$font-display: 'Roboto Condensed', 'Calibri', 'Helvetica', 'Arial', sans-serif;


$break-sm: 576px;
$break-md: 768px;
$break-lg: 992px;
$break-xl: 1200px;

$max-width: 1400px;
$gutter: 24px;
$padding: 24px;
$compact: 12px;

$box-shadow: 0 2px 3px rgba(0,0,0,.1);
$text-shadow: 1px 1px 2px black;
$text-shadow-inverse: 1px 1px 2px white;
$border-radius: 6px;

$color-text-black: #333333;
$color-text-white: white;
$color-text-grey: #b4b4b4;
$color-red: red;
$color-grey1: #f9f9f9; // text background
$color-grey2: #eeeeee; // contact, img-icon, quickinfo
$color-grey3: #494949; // footer, sub-nav
$color-grey4: rgba(190, 190, 190, 0.1); // image background
$color-grey5: #cacaca; // nav-line
$color-blue: #363636; //#2c2943;
$color-green: #363636; //#283842;



// font size -----------------------------------------------------------------------------------------------------------

@mixin fontViewportScale($wMin, $wMax, $fMin, $fMax) {
  $vw: ($fMax - $fMin) / ($wMax - $wMin) * 100;
  $px: $fMax - $wMax / 100px * $vw;
  font-size: calc(#{$px} + #{$vw}vw);
}


// absolute ----------------------------------------------------------------------------------------------------------

@mixin absolute($top: 0, $right: 0, $bottom: 0, $left: 0) {
  position: absolute;
  @if $top != null {
    top: $top;
  }
  @if $right != null {
    right: $right;
  }
  @if $bottom != null {
    bottom: $bottom;
  }
  @if $left != null {
    left: $left;
  }
}



// picture -------------------------------------------------------------------------------------------------------------

@mixin picture-ratio {
  display: block;
  position: relative;
  width: 100%;
  background-color: $color-grey4;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


@mixin picture-fill {
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @supports (object-fit: cover) {
      object-fit: cover;
      object-position: center;
    }
  }
}


// section padding -----------------------------------------------------------------------------------------------------

@mixin padding-main {
  padding-top: 50px;
  padding-bottom: 50px;
  @media(min-width: $break-lg) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@mixin padding-sub {
  padding-top: 25px;
  padding-bottom: 25px;
  @media(min-width: $break-lg) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
}

// kicker --------------------------------------------------------------------------------------------------------------

@mixin kicker {
  text-transform: uppercase;
  color: $color-red;
  margin-bottom: 1 / 1.1 * 1em;
  font-size: 24px;
  & + * {
    margin-top: 0 !important;
  }
  strong {
    font-weight: normal;
  }
}


// grid ----------------------------------------------------------------------------------------------------------------

@mixin grid {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 $compact / 2;
  max-width: 600px;
  @media (min-width: $break-lg) {
    max-width: $max-width;
    padding: 0 $gutter / 2;
  }
}

@mixin column-base {
  box-sizing: border-box;
  width: 100%;
  padding: 0 $compact / 2;
  @media (min-width: #{$break-lg}) {
    padding: 0 $gutter / 2;
  }
}


@function break($break) {
  @if $break == sm {
    @return $break-sm;
  } @else if $break == md {
    @return $break-md;
  } @else if $break == lg {
    @return $break-lg;
  } @else if $break == xl {
    @return $break-xl;
  } @else if $break == null {
    @return null
  } @else {
    @error 'Invalid breakpoint ' + $break;
  }
}

@mixin column($break, $width) {
  $break: break($break);
  @if $break == null {
    width: 100 / 24 * $width * 1%;
  } @else {
    @media (min-width: $break) {
      width: 100 / 24 * $width * 1%;
    }
  }
}


@mixin placeholder($columns, $from: null, $to: null, $display: block) {
  $from: break($from);
  $to: break($to);
  $x: '&:not(&--placeholder):nth-child(#{$columns}n+1) + &';
  @if $columns >= 3 {
    @for $i from 3 through $columns {
      $x: $x + ', ' + $x + ' + &';
    }
  }
  #{$x} {
    @if $from != null and $to != null {
      @media (min-width: $from) and (max-width: $to - 0.02px) {
        display: $display;
      }
    } @else if $from != null {
      @media (min-width: $from) {
        display: $display;
      }
    } @else if $to != null {
      @media (max-width: $to - 0.02px) {
        display: $display;
      }
    } @else {
      display: $display;
    }
  }
}
