

.tiler {
  width: 100%;
  &_grid {
    @include grid;
    margin: -$compact / 2;
    @media (min-width: #{$break-lg}) {
      padding-left: 0;
      padding-right: 0;
      margin: -$gutter / 2 0;
    }
  }
  &_wrap {
    width: 100%;
    position: relative;
  }
  &_item {
    position: absolute;
    top: 0;
  }
  &_inner {
    border-radius: $border-radius;
    overflow: hidden;
    @include absolute($compact / 2, $compact / 2, $compact / 2, $compact / 2);
    @media (min-width: #{$break-lg}) {
      @include absolute($gutter / 2, $gutter / 2, $gutter / 2, $gutter / 2);
    }
    &:hover img {
      transform: scale(1.05);
    }
    &:not(&--placeholder):hover:after {
      content: '';
      position: absolute;
      z-index: 100;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 80%;
    }
    &[href]:after {
      background-color: white;
      background-image: url(/assets/img/icon-arrow-right.svg);
    }
    &[href$=".jpg"]:after {
      background-color: white;
      background-image: url(/assets/img/icon-fullscreen.svg);
    }
  }
  &_picture {
    @include absolute;
    overflow: hidden;
    background-color: $color-grey4;
    img {
      @include absolute(-$compact / 2, -$compact / 2, -$compact / 2, -$compact / 2);
      width: calc(100% + #{$compact});
      height: calc(100% + #{$compact});
      @media (min-width: #{$break-lg}) {
        @include absolute(-$gutter / 2, -$gutter / 2, -$gutter / 2, -$gutter / 2);
        width: calc(100% + #{$gutter});
        height: calc(100% + #{$gutter});
      }
      transition: transform 0.2s ease;
    }
  }
  &_item--bright &_picture:after {
    content: '';
    @include absolute;
    background-color: rgba(255, 255, 255, .75);
  }
  &_item--dark &_picture:after {
    content: '';
    @include absolute;
    background-color: rgba(0, 0, 0, 0.4);
  }
  &_item--blue &_picture:after {
    content: '';
    @include absolute;
    background-color: rgba(12, 11, 80, 0.6);
  }
  &_label {
    @include absolute;
    padding: 0 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-display;
    word-break: break-word;
    color: $color-text-white;
    text-shadow: $text-shadow;
    line-height: 1.4;
    @include fontViewportScale(320px, $break-sm, 16px, 20px);
    @media (min-width: $break-sm) {
      font-size: 20px;
    }
  }
  &_item--dark &_label,
  &_item--blue &_label {
    color: $color-text-white;
    text-shadow: $text-shadow;
  }
  &_item--bright &_label {
    color: $color-text-black;
    text-shadow: $text-shadow-inverse;
  }
}


