

.quick-info {
  $icon-size: 20px;
  $line-padding: 5px;
  background: $color-grey2;
  &_center {
    margin: 0 auto;
    box-sizing: border-box;
    padding: $padding - $line-padding $compact;
    max-width: 600px;
    @media (min-width: $break-lg) {
      padding: $padding - $line-padding $gutter;
      display: flex;
      justify-content: space-between;
      max-width: 900px;
    }
  }
  &_item {
    padding: $line-padding 0 $line-padding $padding + $icon-size;
    background-repeat: no-repeat;
    background-size: $icon-size auto;
    background-position: left center;
    &--email {
      background-image: url(/assets/img/icon-email.svg);
    }
    &--phone {
      background-image: url(/assets/img/icon-phone.svg);
    }
    &--time {
      background-image: url(/assets/img/icon-time.svg);
    }
  }
}
