


.contact {
  @include padding-main;
  background-color: $color-grey2;
  &_grid {
    @include grid;
    justify-content: space-between;
  }
  &_col-left {
    @include column-base;
    @include column(lg, 9);
    @include column(xl, 7);
  }
  &_col-right {
    @include column-base;
    @include column(lg, 14);
    @include column(xl, 16);
    display: flex;
  }
  &_data {
    @media (min-width: $break-lg) {
      max-width: 300px;
    }
    &-item {
      padding-left: $padding + 20px;
      background-repeat: no-repeat;
      background-size: 20px auto;
      background-position: left center;
      &--address {
        background-image: url(/assets/img/icon-marker.svg);
      }
      &--email {
        background-image: url(/assets/img/icon-email.svg);
      }
      &--phone {
        background-image: url(/assets/img/icon-phone.svg);
      }
      &--time {
        background-image: url(/assets/img/icon-time.svg);
      }
      &--map {
        background-image: url(/assets/img/icon-map.svg);
      }
    }
  }
  &_form {
    padding-top: 50px;
    padding-bottom: 50px;
    @media (min-width: $break-lg) {
      padding-bottom: 0;
    }
  }
  &_form-field {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  &_map {
    background: $color-grey4;
    width: 100%;
    position: relative;
    min-height: 500px;
    border-radius: $border-radius;
    overflow: hidden;
    &-picture {
      @include picture-fill;
    }
    &-marker {
      position: absolute;
      bottom: 50%;
      left: 50%;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      transform: translateX(-50%);
      background-image: url(/assets/img/icon-marker-red.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &-attribution {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 10px;
      background: white;
      padding: 0 5px;
      a {
        color: black;
      }
    }
  }
  &_form-success {
    border: 1px solid green;
    background: fade_out(green, 0.95);
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    padding: 5px 10px;
    color: green;
  }
  &_form-error {
    padding: 5px 10px;
    border: 1px solid red;
    background: fade_out(red, 0.95);
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    color: red;
  }
  textarea {
    display: block;
    width: 100%;
  }
  input {
    display: block;
    width: 100%;
  }
  button {
    cursor: pointer;
    color: inherit;
    &:disabled {
      display: none;
    }
    &:hover {
      background: $color-blue;
      color: $color-text-white;
      border-color: $color-blue;
    }
  }
  textarea, input, button {
    padding: 5px 10px;
    border: 1px solid #cdcdcd;
    box-sizing: border-box;
    background: none;
    background: white;
  }

  [data-form-loading] {
    color: transparent;
    position: relative;
    pointer-events: none;
    &:after {
      $size: 0.9em;
      content: '';
      position: absolute;
      display: inline-block;
      width: $size;
      height: $size;
      vertical-align: text-bottom;
      border: 2px solid black;
      border-right-color: transparent;
      border-radius: 50%;
      top: calc(50% - #{$size / 2});
      left: calc(50% - #{$size / 2});
      animation: spin .75s linear infinite;
      box-sizing: border-box;
    }
  }
  &_form-captcha {
    display: flex;
    align-items: center;
    input {
      margin: 0 10px;
    }
    img {
      width: 60px;
      height: 30px;
      border: 1px solid #cdcdcd;
      box-sizing: border-box;
      background: #e4e4e4;
    }
    span {
      flex-grow: 1;
    }
    input {
      width: 60px;
    }
  }
}

@keyframes spin {
  to { transform: rotate(360deg); }
}
