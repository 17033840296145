

.neighbors {
  $line-padding: 5px;
  background: white;
  &_center {
    margin: 0 auto;
    display: flex;
    box-sizing: border-box;
    padding: $padding $compact;
    max-width: 600px;
    flex-wrap: wrap;
    @media (min-width: $break-lg) {
      max-width: $max-width;
      padding: $padding $gutter;
    }
  }
  &_item {
    padding: $line-padding $padding $line-padding 0;
    &:last-child {
      padding-right: 0;
      margin-left: auto;
    }
  }
  &_link {
    background-repeat: no-repeat;
    background-size: 20px auto;
    white-space: nowrap;
    text-decoration: none;
    text-transform: uppercase;
    font-family: $font-display;
  }
  &_item:first-child &_link {
    padding-left: $padding + 20px;
    background-image: url(/assets/img/icon-arrow-left.svg);
    background-position: left center;
  }
  &_item:last-child &_link {
    padding-right: $padding + 20px;
    background-image: url(/assets/img/icon-arrow-right.svg);
    background-position: right center;
  }
}
